import Vue from 'vue';
import { pinia } from '@/plugins/pinia';
import VueMeta from 'vue-meta';
import App from './App.vue';
import router from './router';
import VueSignaturePad from 'vue-signature-pad';

Vue.use(VueMeta);
Vue.use(VueSignaturePad);

/** style **/
import Buefy from 'buefy';
import 'buefy/dist/buefy.css';
import '@mdi/font/css/materialdesignicons.css';
Vue.use(Buefy);
/** end style **/


Vue.config.productionTip = false;

new Vue({
    pinia,
    router,
    render: h => h(App)
}).$mount('#app');
