import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: '/:moduleId',
        name: 'Main',
        component: () => import(/* webpackChunkName: "main" */ '../components/Main.vue')
    },
    {
        path: '/:moduleId/:customUrl',
        name: 'MainCustomURL',
        component: () => import(/* webpackChunkName: "main" */ '../components/Main.vue')
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
